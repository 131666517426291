.app-highlight {
  background-color: color.mix($color_nhsuk-white, $color_nhsuk-warm-yellow, 80%);
  border-bottom: 3px solid $color_nhsuk-warm-yellow;

  a & {
    color: $nhsuk-link-color;
  }

  a:visited & {
    color: $nhsuk-link-visited-color;
  }

  a:hover & {
    color: $nhsuk-link-hover-color;
  }

  a:active & {
    color: $nhsuk-link-active-color;
  }

  a:focus & {
    background-color: transparent;
    border-color: transparent;
    color: $nhsuk-focus-text-color;
  }

  // Ensure highlight is announced by screen readers
  // @link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/mark#accessibility
  &::before,
  &::after {
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(100%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  &::before {
    content: " [highlight start] ";
  }

  &::after {
    content: " [highlight end] ";
  }
}
