.app-action-list {
  @include nhsuk-typography-responsive(19);
  @include nhsuk-responsive-margin(5, "bottom");

  display: flex;
  padding: 0;

  .nhsuk-table__cell & {
    margin-bottom: 0;
  }

  .nhsuk-link {
    @include nhsuk-link-style-no-visited-state;
  }
}

.app-action-list__item {
  align-self: center;
  display: inline-flex;
  margin: 0;
  margin-right: nhsuk-spacing(3);
  padding-right: nhsuk-spacing(3);
}

.app-action-list__item:not(:last-child) {
  border-right: 1px solid $nhsuk-border-color;
}

.app-action-list__item:last-child {
  border: 0;
  margin-right: 0;
  padding-right: 0;
}
