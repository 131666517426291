blockquote {
  @include nhsuk-responsive-margin(4, "bottom");
  @include nhsuk-responsive-margin(2, "top");

  border-left: $nhsuk-border-width solid $nhsuk-border-color;
  margin-left: 0;
  padding: nhsuk-spacing(2);
  padding-left: nhsuk-spacing(3);

  > *:last-child {
    margin-bottom: 0;
  }
}

// Highlight code within body copy
li code,
p code,
td code {
  background-color: $color_nhsuk-white;
  color: $color_nhsuk-red;
  font-family: ui-monospace, monospace;
  font-weight: 500;
  padding: 2px;
}
