$text-message-background-color: color.mix($color_nhsuk-grey-4, $color_nhsuk-white, 50%);

.app-message--email {
  font-family: Helvetica, Arial, sans-serif;

  &__header {
    @include nhsuk-font($size: 16);
    border-bottom: 1px solid $nhsuk-border-color;
    margin-bottom: nhsuk-spacing(4);
    padding-bottom: nhsuk-spacing(3);
  }

  .nhsuk-logo {
    color: $color_nhsuk-blue;
    margin-bottom: nhsuk-spacing(4);
  }

  .nhsuk-heading-l {
    @include nhsuk-font($size: 24, $weight: bold);
  }

  .nhsuk-inset-text {
    @include nhsuk-responsive-padding(2, bottom);
    @include nhsuk-responsive-padding(2, top);
    @include nhsuk-responsive-margin(4, bottom);

    border-color: $nhsuk-border-color;
    margin-top: 0;
  }
}

.app-message--text {
  background-color: $text-message-background-color;
  border-radius: nhsuk-spacing(3);
  font-family: Helvetica, Arial, sans-serif;
  max-width: 400px;
  padding: nhsuk-spacing(3);
  position: relative;

  a {
    word-break: break-all;
  }

  &::before {
    background: $text-message-background-color;
    border-bottom-right-radius: nhsuk-spacing(3);
    bottom: 0;
    content: "";
    height: nhsuk-spacing(3);
    left: #{nhsuk-spacing(2) * -1};
    position: absolute;
    width: nhsuk-spacing(4);
    z-index: 0;
  }

  &::after {
    background: $color_nhsuk-white;
    border-bottom-right-radius: nhsuk-spacing(2);
    bottom: 0;
    content: "";
    height: nhsuk-spacing(3);
    left: #{nhsuk-spacing(2) * -1};
    position: absolute;
    width: nhsuk-spacing(2);
    z-index: 1;
  }

  *:last-child {
    margin: 0;
  }
}
