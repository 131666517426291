// stylelint-disable scss/at-extend-no-missing-placeholder

.nhsuk-file-upload {
  @include nhsuk-font(19, $line-height: 60px);

  &::file-selector-button {
    @extend .nhsuk-button;
    @extend .app-button--secondary;

    font-family: inherit;
    margin-bottom: 0;
  }

  // Button receives focus
  &:focus {
    outline: 0;
  }
}
