// Ensure width of value cell not affected by presence of actions cell
.nhsuk-summary-list:where(:not(:has(.nhsuk-summary-list__actions))) {
  .nhsuk-summary-list__value {
    @include mq($from: tablet) {
      width: 70%;
    }
  }
}

// Show border if row does not contain action cell
.nhsuk-summary-list__row:not(:has(.nhsuk-summary-list__actions))::after {
  border-bottom: 1px solid $nhsuk-border-color;
  content: "";
  display: table-cell;
}

// Ensure border colour inside warning callout can be seen and matches
.nhsuk-warning-callout {
  .nhsuk-summary-list__key,
  .nhsuk-summary-list__value,
  .nhsuk-summary-list__actions,
  .nhsuk-summary-list__row::after {
    border-color: color.scale(color.mix($nhsuk-border-color, $color_nhsuk-yellow, 50%), $lightness: -10%);
  }
}

// Remove bottom border on last row of last child
.nhsuk-summary-list:last-child {
  .nhsuk-summary-list__row:last-of-type > *,
  .nhsuk-summary-list__row:last-of-type::after {
    border: none;
  }
}

// Full width summary list
.app-summary-list--full-width {
  .nhsuk-summary-list__row {
    border-bottom: 1px solid $nhsuk-border-color;
    display: block;
    margin-bottom: nhsuk-spacing(3);

    &:last-of-type {
      border: none;
      margin-bottom: 0;
    }
  }

  &.nhsuk-summary-list--no-border .nhsuk-summary-list__row {
    border-bottom: none;
    margin-bottom: 0;
  }

  .nhsuk-summary-list__key,
  .nhsuk-summary-list__value,
  .nhsuk-summary-list__actions {
    border: none;
    display: block;
    text-align: left;
    width: 100%;
  }

  .nhsuk-summary-list__key {
    margin-bottom: 0;
    padding: 0;
  }

  .nhsuk-summary-list__value {
    margin-bottom: nhsuk-spacing(2);
  }
}
