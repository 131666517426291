.autocomplete__input {
  background-color: $color_nhsuk-white;
}

.autocomplete__option {
  margin-bottom: 0;
}

.autocomplete__option-hint {
  color: $nhsuk-secondary-text-color;

  .autocomplete__option:hover &,
  .autocomplete__option:focus & {
    color: $color_nhsuk-grey-5;
  }
}
