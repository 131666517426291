.app-table--csv {
  i {
    color: shade($color_nhsuk-red, 50%);
    font-family: ui-monospace, monospace;
    font-style: normal;
    font-weight: 500;
    padding: 2px;
  }
}

.app-table--small {
  th,
  td {
    @include nhsuk-typography-responsive(16);
    @include nhsuk-responsive-padding(2, "bottom");
    @include nhsuk-responsive-padding(3, "right");
    @include nhsuk-responsive-padding(2, "top");
  }

  caption {
    @include nhsuk-font($size: 19, $weight: bold);
  }
}

.nhsuk-table__row:hover {
  background: none;
}

.nhsuk-table__cell .nhsuk-button {
  margin-bottom: 0;
  margin-top: -4px;

  &:active {
    margin-top: -3px;
  }
}

.app-table__cell-status {
  &--aqua-green:first-of-type {
    border-left: $nhsuk-border-width solid $color_nhsuk-aqua-green;
    padding-left: nhsuk-spacing(3);
  }

  &--grey {
    background-color: $color_nhsuk-grey-5;
    color: $nhsuk-secondary-text-color;

    &:first-of-type {
      border-left: $nhsuk-border-width solid $color_nhsuk-grey-3;
      padding-left: nhsuk-spacing(3);
    }
  }

  &--red:first-of-type {
    border-left: $nhsuk-border-width solid $color_nhsuk-red;
    padding-left: nhsuk-spacing(3);
  }
}

.app-table--select-cohort {
  .nhsuk-form-group {
    margin-bottom: 0;
  }

  .nhsuk-table__cell,
  th {
    padding-bottom: nhsuk-spacing(1);
    padding-top: nhsuk-spacing(1);
    vertical-align: middle;
  }

  .nhsuk-checkboxes {
    padding: 0;
    transform: scale(0.75);
    transform-origin: center left;
  }

  .nhsuk-checkboxes__item {
    padding: 0;
  }
}
