// Search filters and results
.app-grid-row {
  margin-top: #{nhsuk-spacing(5) * -1};
}

@include govuk-grid-row($class: "app-grid-row");
@include govuk-grid-column(filters, $at: large-desktop, $class: "app-grid-column");
@include govuk-grid-column(results, $at: large-desktop, $class: "app-grid-column");

// Sticky column
.app-grid-column--sticky {
  @include mq($from: desktop) {
    position: sticky;
    top: 0;
  }
}
