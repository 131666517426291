.app-env {
  @include nhsuk-responsive-padding(2, bottom);
  @include nhsuk-responsive-padding(2, top);
  @include nhsuk-typography-responsive(14);

  .nhsuk-width-container {
    align-items: baseline;
    display: flex;
    flex-wrap: wrap;
    gap: nhsuk-spacing(1) nhsuk-spacing(2);
  }
}
