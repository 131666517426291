.app-button-group {
  @include nhsuk-responsive-margin(6, "bottom");

  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: nhsuk-spacing(3) nhsuk-spacing(4);

  .nhsuk-link {
    @include nhsuk-font($size: 19, $line-height: 19px);
    display: inline-block;
    margin-bottom: nhsuk-spacing(2);
    margin-top: nhsuk-spacing(2);
    max-width: 100%;
    text-align: center;
  }

  .nhsuk-button {
    margin-bottom: 0;
    width: 100%;
  }

  @include mq($from: tablet) {
    flex-direction: row;
    flex-wrap: wrap;

    .nhsuk-button {
      width: auto;
    }

    .nhsuk-link {
      text-align: left;
    }
  }
}
