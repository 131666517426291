.nhsuk-tag {
  &--dark-orange {
    background-color: tint($color_app-dark-orange, 80%);
    border-color: shade($color_app-dark-orange, 50%);
    color: shade($color_app-dark-orange, 50%);
  }

  &--transparent {
    background-color: transparent;
    border-color: currentcolor;
    color: currentcolor;
  }
}

.nhsuk-tag + .nhsuk-u-secondary-text-color {
  @include nhsuk-font(16);

  margin-left: nhsuk-spacing(2);
}

.app-tag--attached {
  margin-right: -1px;
}
