$button-shadow-size: 2px;

.govuk-password-input__wrapper {
  align-items: stretch;
}

.govuk-password-input__toggle {
  // Reduce shadow size to align with input border
  box-shadow: 0 $button-shadow-size 0 $nhsuk-secondary-button-shadow-color;

  // Reduce font weight
  font-weight: normal;

  // Adjust bottom margin to offset shadow size given flex-end alignment
  margin-bottom: $button-shadow-size;

  // Add margin to top so that button doesn’t obscure input’s focus style
  margin-top: nhsuk-spacing(1);

  // Adjust padding to match size of input
  padding: #{nhsuk-spacing(1) + 2px};

  // Make full width at smaller breakpoints
  width: 100%;

  &:active {
    top: $button-shadow-size;
  }

  @include mq($from: mobile) {
    // Move spacing from top to the left
    margin-left: nhsuk-spacing(1);
    margin-top: 0;

    // Adjust padding to account for smaller shadow
    padding-bottom: #{nhsuk-spacing(1) - $button-shadow-size};
    padding-top: nhsuk-spacing(1);

    // Reset width
    width: auto;
  }
}
