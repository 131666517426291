@use "sass:color";

.app-patient-banner {
  @include nhsuk-responsive-margin(5, "bottom");
  background: $color_nhsuk-grey-5;
  border-bottom: 1px solid $nhsuk-border-color;
  left: 0;
  margin-left: -50vw;
  margin-right: -50vw;
  right: 0;
  width: 100vw;

  .app-secondary-navigation {
    margin: 0;
  }

  .app-secondary-navigation__list {
    box-shadow: none;

    @include mq($until: tablet) {
      margin-left: #{$nhsuk-gutter-half * -1};
    }
  }
}

.app-patient-banner[data-stuck="true"] {
  border-bottom-color: $color_nhsuk-grey-3;
  box-shadow: 0 $nhsuk-border-width 0 color.scale($color_nhsuk-grey-3, $alpha: -50%);
}
