action-table,
action-table-filters,
action-table-no-results {
  display: block;
}

action-table {
  & button {
    background: none;
    border: 0;
    cursor: pointer;
    font: inherit;
    line-height: inherit;
    padding: 0;
  }

  & th button {
    display: flex;
    gap: nhsuk-spacing(1);
    place-items: center;

    &::after {
      background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 27'%3E%3Cpath fill='%23212b32' d='m13 18.6-7-7.2c-.6-.5-.6-1.4 0-2 .5-.5 1.3-.5 2 0l6 6.2 6-6.2c.7-.5 1.5-.5 2 0 .6.6.6 1.5 0 2l-7 7.2c-.3.3-.6.4-1 .4s-.7-.1-1-.4Z'%3E%3C/path%3E%3C/svg%3E%0A");
      background-position: center right;
      background-repeat: no-repeat;
      background-size: 1em;
      content: "";
      display: block;
      height: 1em;
      opacity: 0.2;
      width: 1em;
    }
  }

  & th[aria-sort$="ing"] button::after {
    opacity: 1;
  }

  & th[aria-sort="descending"] button::after {
    opacity: 1;
    transform: rotate(180deg);
  }

  // Hide table if no results shown
  &:has(action-table-no-results) {
    &:has(action-table-no-results[style=""]) table {
      display: none;
    }
  }
}
