.app-header__content {
  display: flex;
  flex-flow: column wrap;
  gap: nhsuk-spacing(2);

  @include mq($until: tablet) {
    margin-top: nhsuk-spacing(3);
  }
}

.app-header__navigation-item--current {
  box-shadow: inset 0 ($nhsuk-focus-width * -1) $nhsuk-border-color;

  .nhsuk-header__drop-down & {
    box-shadow: inset $nhsuk-focus-width 0 $nhsuk-link-color;
  }
}

.app-header__navigation-item--with-count {
  .app-count {
    @include nhsuk-font(14, $line-height: 1);
    margin-left: 6px;
    min-width: nhsuk-spacing(4);
    padding-bottom: 3px;
    padding-top: 5px;
    text-decoration: none;
  }
}

.app-header__account {
  border-radius: $nhsuk-border-radius;
  display: flex;
  flex-wrap: wrap;
  gap: 1px;
  overflow: hidden;
}

.app-header__account-item {
  align-items: start;
  background-color: color.scale($nhsuk-link-color, $lightness: -20%);
  color: $color_nhsuk-white;
  display: inline-flex;
  flex-grow: 1;
  gap: nhsuk-spacing(2);
  padding: nhsuk-spacing(2) nhsuk-spacing(3);

  &:first-child {
    flex-grow: 999;
  }
}

.app-header__account-icon {
  flex-shrink: 0;
  position: relative;
  top: 1px;
}

.app-header__account-link {
  color: $color_nhsuk-white;
  display: block;
  margin: #{nhsuk-spacing(2) * -1} #{nhsuk-spacing(3) * -1};
  padding: nhsuk-spacing(2) nhsuk-spacing(3);

  &:visited {
    color: $color_nhsuk-white;
  }

  &:active,
  &:focus {
    background-color: $nhsuk-focus-color;
    border-bottom: $nhsuk-focus-width solid $nhsuk-focus-text-color;
    color: $nhsuk-focus-text-color;
    padding-bottom: nhsuk-spacing(1);
  }
}
