.nhsuk-tag {
  &--dark-orange {
    background-color: tint($color_app-dark-orange, 80%);
    border-color: shade($color_app-dark-orange, 50%);
    color: shade($color_app-dark-orange, 50%);
  }
}

.nhsuk-tag + .nhsuk-u-secondary-text-color {
  @include nhsuk-font(16, $line-height: 1.2);

  display: block;
  margin-top: 12px;
}
