.app-count {
  @include nhsuk-font(16);

  background-color: color.scale(color.scale($nhsuk-link-color, $lightness: -50%), $alpha: -60%);
  border-radius: nhsuk-spacing(3);
  color: $color_nhsuk-white;
  display: inline-block;
  min-width: nhsuk-spacing(5);
  padding-left: nhsuk-spacing(2);
  padding-right: nhsuk-spacing(2);
  text-align: center;

  :focus & {
    background-color: color.scale($nhsuk-focus-text-color, $alpha: -40%);
  }
}
