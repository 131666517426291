.app-filters {
  &.nhsuk-card {
    background-color: color.scale($color_nhsuk-grey-4, $alpha: -50%);
  }

  .nhsuk-card__heading--feature {
    background-color: $color_nhsuk-grey-1;
  }

  // Adjust design and spacing of details component
  .nhsuk-details {
    margin-left: #{nhsuk-spacing(4) * -1};
    margin-right: #{nhsuk-spacing(4) * -1};

    @include mq($from: tablet) {
      margin-left: #{nhsuk-spacing(5) * -1};
      margin-right: #{nhsuk-spacing(5) * -1};
    }

    &[open] {
      background-color: $color_nhsuk-grey-4;
    }
  }

  .nhsuk-details__summary {
    @include nhsuk-responsive-margin(5, "left");
    @include nhsuk-responsive-margin(5, "right");
  }

  .nhsuk-details__text {
    @include nhsuk-responsive-padding(5, "left");
    @include nhsuk-responsive-padding(5, "right");

    border-left: 0;
  }

  // Adjust margin around date inputs
  & .nhsuk-date-input__item {
    margin-right: nhsuk-spacing(3);
  }

  & .nhsuk-date-input__item:last-child {
    margin-right: 0;
  }

  // Adjust sizing of checkbox and radio items
  & .nhsuk-checkboxes__item,
  & .nhsuk-radios__item {
    margin-bottom: 0;
  }

  & .nhsuk-checkboxes__label,
  & .nhsuk-radios__label {
    padding-left: 0;

    &::before {
      border-width: 3px;
      padding: 0;
      transform: scale(0.6667);
      transform-origin: center left;
    }

    &::after {
      left: 3px;
      transform: rotate(-45deg) scale(0.6667);
    }
  }
}
