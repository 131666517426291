$button-shadow-size: 2px;

.app-search-input {
  @include nhsuk-responsive-margin(4, "bottom");
  display: flex;
  gap: nhsuk-spacing(1);

  .nhsuk-input {
    &[type="search"]::-webkit-search-cancel-button {
      -webkit-appearance: none;
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23212b32'><path d='M13.41 12l5.3-5.29a1 1 0 1 0-1.42-1.42L12 10.59l-5.29-5.3a1 1 0 0 0-1.42 1.42l5.3 5.29-5.3 5.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l5.29-5.3 5.29 5.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z'/></svg>");
      cursor: pointer;
      height: 28px;
      margin: 0;
      width: 28px;
    }
  }

  .nhsuk-form-group {
    display: flex;
    flex: 1;
    margin: 0;
  }
}

.app-search-input__submit {
  // Reduce shadow size to align with input border
  box-shadow: 0 $button-shadow-size 0 $nhsuk-secondary-button-shadow-color;

  // Keep button remains aligned with input
  display: flex;
  flex: 0;

  // Adjust bottom margin to offset shadow size given flex-end alignment
  margin-bottom: $button-shadow-size;

  // Adjust inline padding
  padding: 0 nhsuk-spacing(2);

  // Align icon in center of button
  place-items: center;

  &:active {
    top: $button-shadow-size;
  }
}
