// stylelint-disable scss/dollar-variable-pattern
$color_prototype: $color_nhsuk-pink;
$color_prototype-alpha: rgba($color_prototype, 10%);

.app-prototype {
  @include nhsuk-responsive-margin(7, "bottom");
  @include nhsuk-responsive-margin(7, "top");
  @include nhsuk-responsive-padding(4);

  &::before {
    @include nhsuk-font(19);
    color: $color_prototype;
    content: "Prototype";
  }

  background-image: linear-gradient(
    -45deg,
    transparent 25%,
    $color_prototype-alpha 25%,
    $color_prototype-alpha 50%,
    transparent 50%,
    transparent 75%,
    $color_prototype-alpha 75%,
    $color_prototype-alpha 100%
  );
  background-repeat: repeat;
  background-size: nhsuk-spacing(2) nhsuk-spacing(2);
  border-left: $nhsuk-border-width-inset-text solid $color_prototype;
}
