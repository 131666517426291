$app-cis2-button-color: $color_nhsuk-blue;
$app-cis2-button-hover-color: color.mix($app-cis2-button-color, #003087, 50%);
$app-cis2-button-active-color: #003087;
$app-cis2-button-shadow-color: #003087;
$button-shadow-size: 4px;

.nhsuk-button[hidden] {
  display: none;
}

.app-button--cis2 {
  align-items: center;
  background-color: $app-cis2-button-color;
  box-shadow: 0 $button-shadow-size 0 $app-cis2-button-shadow-color;
  display: inline-flex;
  gap: nhsuk-spacing(3);

  & span {
    @include mq($until: desktop) {
      @include visually-hidden;
    }
  }

  &:hover {
    background-color: $app-cis2-button-hover-color;
  }

  &:focus {
    background: $nhsuk-focus-color;
    box-shadow: 0 $button-shadow-size 0 $nhsuk-focus-text-color;
    color: $nhsuk-focus-text-color;
    outline: $nhsuk-focus-width solid transparent;
  }

  &:active {
    background: $app-cis2-button-active-color;
    box-shadow: none;
    color: $nhsuk-button-text-color;
    top: $button-shadow-size;
  }
}

// Proposed secondary button style
// See https://github.com/nhsuk/nhsapp-frontend/issues/12
// See https://github.com/nhsuk/nhs-app-design-styles-components-patterns
@mixin app-button-secondary($button-color) {
  background-color: transparent;
  border-bottom: 0;
  border-color: $button-color;
  box-shadow: 0 4px 0 $button-color;
  color: $button-color;
  // Adjust padding to account for removal of 2px bottom border
  padding-bottom: 13px;
  padding-top: 13px;

  &:link,
  &:visited {
    color: $button-color;
  }

  &:hover {
    background-color: rgba($button-color, 10%);
    color: $button-color;
  }

  &:focus {
    background-color: $nhsuk-focus-color;
    border-color: $nhsuk-focus-color;
    color: $nhsuk-focus-text-color;
  }

  &:focus:visited:active {
    // Override .nhsuk-button turning colour white
    color: $button-color;
  }

  &:active {
    background-color: rgba($button-color, 15%);
    border-bottom: $nhsuk-border-width-form-element solid; // Reintroduce 2px bottom border
    border-color: $button-color;
    color: $button-color;
    // Revert padding to account for reintroduction of 2px bottom border
    padding-bottom: 12px;
    padding-top: 12px;
  }

  &::before {
    bottom: #{$button-shadow-size * -1};
    top: #{$nhsuk-border-width-form-element * -1};
  }

  &:active::before {
    bottom: #{($nhsuk-border-width-form-element + $button-shadow-size) * -1};
    top: #{($nhsuk-border-width-form-element + $button-shadow-size) * -1};
  }
}

.app-button--secondary {
  @include app-button-secondary($nhsuk-link-color);
}

.app-button--secondary-warning {
  @include app-button-secondary($nhsuk-warning-button-color);
}

.app-button--small {
  @include nhsuk-typography-responsive(16);
  $padding-block: 6px;
  $padding-block-icon: 3px;
  $padding-inline: 12px;
  $padding-inline-icon: 6px;

  padding: $padding-block $padding-inline;

  &.app-button--secondary,
  &.app-button--secondary-warning {
    // Adjust padding to account for removal of 2px bottom border
    padding: #{$padding-block + 1px} $padding-inline;

    &.app-button--icon {
      padding: #{$padding-block-icon + 1px} $padding-inline-icon;
    }

    &:active {
      margin-bottom: -1px;
      // Revert padding to account for reintroduction of 2px bottom border
      padding: $padding-block $padding-inline;
    }

    &.app-button--icon:active {
      // Revert padding to account for reintroduction of 2px bottom border
      padding: $padding-block-icon $padding-inline-icon;
    }
  }
}

.app-button--icon {
  .nhsuk-icon {
    display: block;
    height: 28px;
    width: 28px;
  }
}
